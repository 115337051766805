import React, { useEffect, useState } from "react";
import {
  Container,
  Box,
  Typography,
  Grid,
  Divider,
  Button,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
// import Logo from 'src/@core/components/logo'
import { styled } from "@mui/system";
import bgImg from "../../assets/images/footer-bg-img.png";
import bgImgSmall from "../../assets/images/footerBgImg.png";
import logo from "../../assets/images/logo.png";
import footerImg from "../../assets/images/footer-Img.png";
import footerImgSmall from "../../assets/images/footerBgImg2.png";
import linkedinLogo from "../../assets/images/linkedin-logo.png";
import faceBookLogo from "../../assets/images/facebook-logo.png";
import instagramLogo from "../../assets/images/instagram-logo.png";
import { axiosInstance } from "src/network/adapter";
import { ApiEndpoints } from "src/network/endpoints";
import { toastError } from "src/utils/utils";
import { useAuth } from "src/hooks/useAuth";

const Footer = () => {
  const { appBarData } = useAuth();
  const [linkedin, setLinkedin] = useState([]);
  const [facebook, setFacebook] = useState([]);
  const [instagram, setInstagram] = useState([]);
  const naviagte = useNavigate();

  const handleITSolutionClick = () => {
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    if (appBarData) {
      appBarData.map((item) => {
        setLinkedin(item.linkedin);
        setFacebook(item.facebook);
        setInstagram(item.instagram);
      });
    }
  }, []);
  console.log("linkedin", linkedin);
  console.log("facebook", facebook);
  console.log("instagram", instagram);

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);
  return (
    <>
      <Box
        sx={{
          // height: "550px",
          backgroundImage: { xs: `url(${bgImgSmall})`, md: `url(${bgImg})` },
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          // backgroundColor: "#F5F5F9",
          // clipPath:
          //   'path("M0,96 C543,190 559,0 1632,0 C3000,454 2060,531 2204,463 L2018,515 L0,700 Z")',
        }}
      >
        <Container
          maxWidth="xl"
          sx={{
            backgroundImage: {
              xs: `url(${footerImgSmall})`,
              md: `url(${footerImg})`,
            },
            backgroundRepeat: "no-repeat",
            backgroundPosition: { xs: "bottom right", md: "left bottom" },
            pt: { xs: 15, sm: 20, md: 40, lg: 40, xl: 50 },
            // pt: { xs: 30, md: 40, lg: 20, xl: 10 },
            pb: { lg: 3, xl: 5 },
            px: { xs: 5, sm: 10 },
          }}
        >
          <Grid
            container
            rowSpacing={{ xs: 5, md: 10 }}
            sx={{ px: { md: 10 } }}
          >
            <Grid item container xs={12} md={5} sx={{ px: { lg: 5 } }}>
              <Box
                sx={{ width: { xs: "100px", md: "174px" }, cursor: "pointer" }}
                onClick={() => naviagte("/")}
              >
                <img src={logo} alt="Logo" style={{ width: "100%" }} />
              </Box>
            </Grid>
            <Grid
              item
              container
              xs={12}
              md={7}
              // columnSpacing={{ xs: 5, md: 0 }}
              sx={{ px: { lg: 15 }, mt: { md: 3, lg: 5 } }}
            >
              <Grid
                item
                xs={6}
                md={4}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: { xs: 2, md: 5 },
                }}
              >
                <Typography
                  sx={{
                    fontWeight: 700,
                    color: "#000000",
                    fontSize: { xs: "16px", sm: "18px", md: "20px" },
                    lineHeight: { xs: "24px", md: "31px" },
                  }}
                >
                  QUICK LINKS
                </Typography>
                <Link style={{ textDecoration: "none" }} to={"/about-us"}>
                  <Typography
                    sx={{
                      fontWeight: 500,
                      color: "#000000",
                      fontSize: { xs: "14px", sm: "16px", md: "20px" },
                      lineHeight: { xs: "22px", md: "31px" },
                    }}
                  >
                    About Us
                  </Typography>
                </Link>
                <Link style={{ textDecoration: "none" }} to={"/services"}>
                  {" "}
                  <Typography
                    sx={{
                      fontWeight: 500,
                      color: "#000000",
                      fontSize: { xs: "14px", sm: "16px", md: "20px" },
                      lineHeight: { xs: "22px", md: "31px" },
                    }}
                  >
                    Services
                  </Typography>
                </Link>
                {/* <Typography
                  sx={{
                    fontWeight: 500,
                    color: "#000000",
                    fontSize: { xs: "14px", sm: "16px", md: "20px" },
                    lineHeight: { xs: "22px", md: "31px" },
                    cursor: "pointer",
                  }}
                  onClick={handleITSolutionClick}
                >
                  IT Solutions
                </Typography> */}
                <Link style={{ textDecoration: "none" }} to={"/careers"}>
                  <Typography
                    sx={{
                      fontWeight: 500,
                      color: "#000000",
                      fontSize: { xs: "14px", sm: "16px", md: "20px" },
                      lineHeight: { xs: "22px", md: "31px" },
                    }}
                  >
                    Career
                  </Typography>
                </Link>
                {/* <Link style={{ textDecoration: "none" }} to={"/blogs"}>
                  {" "}
                  <Typography
                    sx={{
                      fontWeight: 500,
                      color: "#000000",
                      fontSize: { xs: "14px", sm: "16px", md: "20px" },
                      lineHeight: { xs: "22px", md: "31px" },
                    }}
                  >
                    Resources
                  </Typography>
                </Link> */}
              </Grid>
              <Grid
                item
                xs={6}
                md={4}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: { xs: 2, md: 5 },
                }}
              >
                <Typography
                  sx={{
                    fontWeight: 700,
                    color: "#000000",
                    fontSize: { xs: "16px", sm: "18px", md: "20px" },
                    lineHeight: { xs: "24px", md: "31px" },
                  }}
                >
                  LEGAL
                </Typography>
                <Link style={{ textDecoration: "none" }} to={"/contact-us"}>
                  {" "}
                  <Typography
                    sx={{
                      fontWeight: 500,
                      color: "#000000",
                      fontSize: { xs: "14px", sm: "16px", md: "20px" },
                      lineHeight: { xs: "22px", md: "31px" },
                    }}
                  >
                    Contact Us
                  </Typography>
                </Link>
                <Link
                  style={{ textDecoration: "none" }}
                  to={"/terms-and-conditions"}
                >
                  {" "}
                  <Typography
                    sx={{
                      fontWeight: 500,
                      color: "#000000",
                      fontSize: { xs: "14px", sm: "16px", md: "20px" },
                      lineHeight: { xs: "22px", md: "31px" },
                    }}
                  >
                    Terms of service
                  </Typography>
                </Link>
                <Link style={{ textDecoration: "none" }} to={"/privacy-policy"}>
                  <Typography
                    sx={{
                      fontWeight: 500,
                      color: "#000000",
                      fontSize: { xs: "14px", sm: "16px", md: "20px" },
                      lineHeight: { xs: "22px", md: "31px" },
                    }}
                  >
                    Privacy Policy
                  </Typography>
                </Link>
                <Link style={{ textDecoration: "none" }} to={"/faqs"}>
                  {" "}
                  <Typography
                    sx={{
                      fontWeight: 500,
                      color: "#000000",
                      fontSize: { xs: "14px", sm: "16px", md: "20px" },
                      lineHeight: { xs: "22px", md: "31px" },
                    }}
                  >
                    FAQs
                  </Typography>
                </Link>
              </Grid>
              <Grid
                item
                xs={12}
                md={4}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: { xs: 2, md: 5 },
                  mt: { xs: 6, md: 0 },
                }}
              >
                <Typography
                  sx={{
                    fontWeight: 700,
                    color: "#000000",
                    fontSize: { xs: "16px", sm: "18px", md: "20px" },
                    lineHeight: { xs: "24px", md: "31px" },
                  }}
                >
                  REACH TO US
                </Typography>
                {typeof linkedin === "string" && linkedin.trim() && (
                  <Link
                    style={{ textDecoration: "none" }}
                    to={linkedin}
                    target="_blank"
                  >
                    <Box sx={{ display: "flex", alignItems: "center", gap: 3 }}>
                      <Box
                        sx={{
                          height: "32px",
                          width: "32px",
                          borderRadius: "50px",
                          backgroundColor: "#602DE1",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          flexShrink: 0,
                        }}
                      >
                        <img src={linkedinLogo} alt="Linkedin Logo" />
                      </Box>
                      <Typography
                        sx={{
                          fontWeight: 500,
                          color: "#000000",
                          fontSize: { xs: "14px", sm: "16px", md: "20px" },
                          lineHeight: { xs: "22px", md: "31px" },
                          wordBreak: "break-word",
                          whiteSpace: "normal",
                        }}
                      >
                        LinkedIn
                      </Typography>
                    </Box>
                  </Link>
                )}
                {typeof facebook === "string" && facebook.trim() && (
                  <Link
                    style={{ textDecoration: "none" }}
                    to={facebook}
                    target="_blank"
                  >
                    <Box sx={{ display: "flex", alignItems: "center", gap: 3 }}>
                      <Box
                        sx={{
                          height: "32px",
                          width: "32px",
                          borderRadius: "50px",
                          backgroundColor: "#602DE1",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          flexShrink: 0,
                        }}
                      >
                        <img src={faceBookLogo} alt="Facebook Logo" />
                      </Box>
                      <Typography
                        sx={{
                          fontWeight: 500,
                          color: "#000000",
                          fontSize: { xs: "14px", sm: "16px", md: "20px" },
                          lineHeight: { xs: "22px", md: "31px" },
                          wordBreak: "break-word",
                          whiteSpace: "normal",
                        }}
                      >
                        Facebook
                      </Typography>{" "}
                    </Box>
                  </Link>
                )}
                {typeof instagram === "string" && instagram.trim() && (
                  <Link
                    style={{ textDecoration: "none" }}
                    to={instagram}
                    target="_blank"
                  >
                    <Box sx={{ display: "flex", alignItems: "center", gap: 3 }}>
                      <Box
                        sx={{
                          height: "32px",
                          width: "32px",
                          borderRadius: "50px",
                          backgroundColor: "#602DE1",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          flexShrink: 0,
                        }}
                      >
                        <img src={instagramLogo} alt="Instagram Logo" />
                      </Box>
                      <Typography
                        sx={{
                          fontWeight: 500,
                          color: "#000000",
                          fontSize: { xs: "14px", sm: "16px", md: "20px" },
                          lineHeight: { xs: "22px", md: "31px" },
                          wordBreak: "break-word",
                          whiteSpace: "normal",
                        }}
                      >
                        Instagram
                      </Typography>{" "}
                    </Box>
                  </Link>
                )}
              </Grid>
            </Grid>
            {/* <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                justifyContent: "end",
              }}
            >
              <Box
                sx={{
                  height: "80px",
                  width: "80px",
                  borderRadius: "50px",
                  backgroundColor: "#602DE1",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img src={messageLogo} alt="Message" />
              </Box>
            </Grid> */}
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "end",
                pb: 4,
              }}
            >
              <Typography
                sx={{
                  fontWeight: 500,
                  color: "#8B8994",
                  fontSize: { xs: "14px", md: "16px" },
                  lineHeight: "24px",
                }}
              >
                © 2024 | ALL RIGHTS RESERVED
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default Footer;
