// ** React Imports
import { createContext, useEffect, useState } from "react";

// ** Next Import
import { Routes, useNavigate, useSearchParams } from "react-router-dom";

// ** Axios
import axios from "axios";

// ** Config
import authConfig from "../configs/auth";
import FallbackSpinner from "src/@core/components/spinner";
import { ApiEndpoints } from "src/network/endpoints";
import { axiosInstance } from "src/network/adapter";
import toast from "react-hot-toast";

// ** Defaults
const defaultProvider = {
  user: null,
  loading: true,
  otpVerifyData: null,

  resources: false,
  setResources: () => false,
  overview: false,
  setOverview: () => false,
  features: false,
  setFeatures: () => false,
  ITSolutionId: null,
  setITSolutionId: () => null,
  appBarData: null,
  setAppBarData: () => null,
  overviewPlan: null,
  setOverviewPlan: () => null,
  slug: "",
  setSlug: () => "",

  setUser: () => null,
  setLoading: () => Boolean,
  isInitialized: false,
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  setIsInitialized: () => Boolean,
  signup: () => Promise.resolve(),
  fetchCommonData: () => Promise.resolve(),
};
const AuthContext = createContext(defaultProvider);

const AuthProvider = ({ children }) => {
  // ** States
  const [user, setUser] = useState(defaultProvider.user);
  const [loading, setLoading] = useState(defaultProvider.loading);
  const [isInitialized, setIsInitialized] = useState(
    defaultProvider.isInitialized
  );
  const [otpVerifyData, setOtpVerifyData] = useState(null);

  const [resources, setResources] = useState(defaultProvider.resources);
  const [overview, setOverview] = useState(defaultProvider.overview);
  const [features, setFeatures] = useState(defaultProvider.features);
  const [ITSolutionId, setITSolutionId] = useState(
    defaultProvider.ITSolutionId
  );
  const [appBarData, setAppBarData] = useState(defaultProvider.appBarData);
  const [overviewPlan, setOverviewPlan] = useState(
    defaultProvider.overviewPlan
  );
  const [slug, setSlug] = useState(defaultProvider.slug);
  // ** Hooks
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  // useEffect(() => {
  //   const initAuth = async () => {
  //     setIsInitialized(true);
  //     const storedToken = window.localStorage.getItem(
  //       authConfig.storageTokenKeyName
  //     );
  //     if (storedToken) {
  //       setLoading(true);
  //       await axiosInstance
  //         .get(ApiEndpoints.AUTH.me, {
  //           headers: {
  //             Authorization: storedToken,
  //           },
  //         })
  //         .then(async (response) => {
  //           setLoading(false);
  //           let user = { ...response.data.data.user };
  //           setUser({ ...user });
  //           window.localStorage.setItem(
  //             authConfig.storageUserDataKeyName,
  //             JSON.stringify(user)
  //           );
  //         })
  //         .catch(() => {
  //           localStorage.removeItem(authConfig.storageUserDataKeyName);
  //           localStorage.removeItem(authConfig.storageTokenKeyName);
  //           setUser(null);
  //           setLoading(false);
  //         });
  //     } else {
  //       setLoading(false);
  //     }
  //   };
  //   initAuth();
  // }, []);

  useEffect(() => {
    const initAuth = async () => {
      setIsInitialized(true);
      const storedToken = window.localStorage.getItem(
        authConfig.storageTokenKeyName
      );
      if (storedToken) {
        setLoading(true);
        await axiosInstance
          .get(ApiEndpoints.AUTH.me, {
            headers: {
              Authorization: storedToken,
            },
          })
          .then(async (response) => {
            setLoading(false);
            let user = { ...response.data.data.user };
            setUser({ ...user });
            window.localStorage.setItem(
              authConfig.storageUserDataKeyName,
              JSON.stringify(user)
            );
          })
          .catch(() => {
            localStorage.removeItem(authConfig.storageUserDataKeyName);
            localStorage.removeItem(authConfig.storageTokenKeyName);
            setUser(null);
            setLoading(false);
          });
      } else {
        setLoading(false);
      }
    };
    initAuth();
  }, []);

  const handleLogin = ({ token, user }) => {
    window.localStorage.setItem(authConfig.storageTokenKeyName, token);
    setUser(user);
    const redirectUrl = searchParams.get("redirect");
    navigate(redirectUrl || "/");
  };

  const handleLogout = () => {
    setUser(null);
    setIsInitialized(false);
    window.localStorage.removeItem(authConfig.storageUserDataKeyName);
    window.localStorage.removeItem(authConfig.storageTokenKeyName);
    navigate("/login");
  };

  const handleSignUp = () => {};

  const initVerifyOTP = (data) => {
    setOtpVerifyData(data);
    navigate("/verify-otp");
  };

  const handleVerifyOtp = (type, params) => {
    axiosInstance
      .post(ApiEndpoints.OTP.verifyotp(type), params)
      .then((response) => {
        toast.success(response.data.message);
        localStorage.setItem(
          authConfig.storageTokenKeyName,
          response.data.data.token
        );
        let user = { ...response.data.data.user };
        window.localStorage.setItem(
          authConfig.storageUserDataKeyName,
          JSON.stringify(user)
        );
        setUser({ ...user });
        navigate("/home");
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  useEffect(() => {
    if (ITSolutionId) {
      // Fetch data again when the ITSolutionId is available or on refresh
      fetchCommonData(ITSolutionId);
    }
  }, [ITSolutionId]);

  const fetchCommonData = async (itSolutionId) => {
    try {
      const response = await axiosInstance.get(
        ApiEndpoints.COMMON.list(itSolutionId)
      );

      const { features, overView, resources } = response.data;

      // Set values in context
      setFeatures(features?.is_show || false);
      setOverview(overView?.is_show || false);
      setResources(resources?.is_show || false);
    } catch (error) {
      console.error("Error fetching common data:", error);

      // Set default values in case of an error
      setFeatures(false);
      setOverview(false);
      setResources(false);
    }
  };

  const fetchAppBarData = () => {
    axiosInstance
      .get(ApiEndpoints.HOME.list)
      .then((response) => {
        const responseData = response.data.data;
        setAppBarData(responseData);
      })
      .catch((error) => {
        toast.error(error);
      });
  };
  useEffect(() => {
    fetchAppBarData();
  }, []);
  const values = {
    user,
    loading,
    otpVerifyData,
    setUser,
    setLoading,
    isInitialized,
    setIsInitialized,
    login: handleLogin,
    verifyOtp: handleVerifyOtp,
    logout: handleLogout,
    signup: handleSignUp,
    initVerifyOTP: initVerifyOTP,

    ITSolutionId: ITSolutionId,
    setITSolutionId: setITSolutionId,
    appBarData: appBarData,
    setAppBarData: setAppBarData,
    overviewPlan: overviewPlan,
    setOverviewPlan: setOverviewPlan,
    slug: slug,
    setSlug: setSlug,

    resources: resources,
    setResources: setResources,
    overview: overview,
    setOverview: setOverview,
    features: features,
    setFeatures: setFeatures,
  };

  return (
    <AuthContext.Provider value={values}>
      {loading ? <FallbackSpinner /> : children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };
