// ** React Import
import { useRef, useState } from "react";

// ** MUI Import
import List from "@mui/material/List";
import Box from "@mui/material/Box";
import { styled, useTheme } from "@mui/material/styles";

// ** Third Party Components
import PerfectScrollbar from "react-perfect-scrollbar";
import themeConfig from "src/configs/themeConfig";

// ** Component Imports
import Drawer from "./Drawer";
import VerticalNavItems from "./VerticalNavItems";
import VerticalNavHeader from "./VerticalNavHeader";

// ** Util Import
import { hexToRGBA } from "src/@core/utils/hex-to-rgba";

import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { Typography } from "@mui/material";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import phoneIcon from "../../../../../assets/images/phone-call.svg";
import { ApiEndpoints } from "src/network/endpoints";
import { axiosInstance } from "src/network/adapter";
import { toastError } from "src/utils/utils";
import { useAuth } from "src/hooks/useAuth";

const StyledBoxForShadow = styled(Box)(({ theme }) => ({
  top: 60,
  left: -8,
  zIndex: 2,
  display: "none",
  position: "absolute",
  pointerEvents: "none",
  width: "calc(100% + 15px)",
  height: theme.mixins.toolbar.minHeight,
  "&.d-block": {
    display: "block",
  },
}));

const Navigation = (props) => {
  // ** Props
  const {
    hidden,
    navHover,
    settings,
    afterVerticalNavMenuContent,
    beforeVerticalNavMenuContent,
    verticalNavMenuContent: userVerticalNavMenuContent,
  } = props;
  const { appBarData } = useAuth();

  // ** States
  const [groupActive, setGroupActive] = useState([]);
  const [currentActiveGroup, setCurrentActiveGroup] = useState([]);

  // ** Ref
  const shadowRef = useRef(null);

  // ** Hooks
  const theme = useTheme();

  // ** Var
  const { skin, navCollapsed } = settings;
  const {
    afterVerticalNavMenuContentPosition,
    beforeVerticalNavMenuContentPosition,
  } = themeConfig;

  // ** Fixes Navigation InfiniteScroll
  const handleInfiniteScroll = (ref) => {
    if (ref) {
      // @ts-ignore
      // ref._getBoundingClientRect = ref.getBoundingClientRect
      // ref.getBoundingClientRect = () => {
      //   // @ts-ignore
      //   const original = ref._getBoundingClientRect()
      //   return { ...original, height: Math.floor(original.height) }
      // }
    }
  };

  // ** Scroll Menu
  const scrollMenu = (container) => {
    if (
      beforeVerticalNavMenuContentPosition === "static" ||
      !beforeVerticalNavMenuContent
    ) {
      container = hidden ? container.target : container;
      if (shadowRef && container.scrollTop > 0) {
        // @ts-ignore
        if (!shadowRef.current.classList.contains("d-block")) {
          // @ts-ignore
          shadowRef.current.classList.add("d-block");
        }
      } else {
        // @ts-ignore
        shadowRef.current.classList.remove("d-block");
      }
    }
  };

  const shadowBgColor = () => {
    if (skin === "semi-dark" && theme.palette.mode === "light") {
      return `linear-gradient(${
        theme.palette.customColors.darkBg
      } 5%,${hexToRGBA(
        theme.palette.customColors.darkBg,
        0.85
      )} 30%,${hexToRGBA(
        theme.palette.customColors.darkBg,
        0.5
      )} 65%,${hexToRGBA(
        theme.palette.customColors.darkBg,
        0.3
      )} 75%,transparent)`;
    } else if (skin === "semi-dark" && theme.palette.mode === "dark") {
      return `linear-gradient(${
        theme.palette.customColors.lightBg
      } 5%,${hexToRGBA(
        theme.palette.customColors.lightBg,
        0.85
      )} 30%,${hexToRGBA(
        theme.palette.customColors.lightBg,
        0.5
      )} 65%,${hexToRGBA(
        theme.palette.customColors.lightBg,
        0.3
      )} 75%,transparent)`;
    } else {
      return `linear-gradient(${
        theme.palette.background.default
      } 5%,${hexToRGBA(theme.palette.background.default, 0.85)} 30%,${hexToRGBA(
        theme.palette.background.default,
        0.5
      )} 65%,${hexToRGBA(
        theme.palette.background.default,
        0.3
      )} 75%,transparent)`;
    }
  };
  const ScrollWrapper = hidden ? Box : PerfectScrollbar;

  const [mobileNumber, setMobileNumber] = useState("");

  useEffect(() => {
    // const appBarData = JSON.parse(localStorage.getItem("appBarData"));
    if (appBarData) {
      appBarData.map((item) => {
        setMobileNumber(item.mobile_number);
      });
    }
  }, []);
  return (
    <Drawer {...props}>
      <Box
        sx={{
          ...(afterVerticalNavMenuContentPosition === "fixed"
            ? { flex: 1 }
            : {}),
        }}
      >
        <VerticalNavHeader {...props} />

        {beforeVerticalNavMenuContent &&
        beforeVerticalNavMenuContentPosition === "fixed"
          ? beforeVerticalNavMenuContent(props)
          : null}
        {(beforeVerticalNavMenuContentPosition === "static" ||
          !beforeVerticalNavMenuContent) && (
          <StyledBoxForShadow
            ref={shadowRef}
            sx={{ background: shadowBgColor() }}
          />
        )}
        <Box
          sx={{
            position: "relative",
            overflow: "hidden",
            pt: !navCollapsed || (navCollapsed && navHover) ? 4.5 : 1.25,
          }}
        >
          <ScrollWrapper
            containerRef={(ref) => handleInfiniteScroll(ref)}
            {...(hidden
              ? {
                  onScroll: (container) => scrollMenu(container),
                  sx: {
                    height: "100%",
                    overflowY: "auto",
                    overflowX: "hidden",
                  },
                }
              : {
                  options: { wheelPropagation: false },
                  onScrollY: (container) => scrollMenu(container),
                })}
          >
            {beforeVerticalNavMenuContent &&
            beforeVerticalNavMenuContentPosition === "static"
              ? beforeVerticalNavMenuContent(props)
              : null}
            {userVerticalNavMenuContent ? (
              userVerticalNavMenuContent(props)
            ) : (
              <List
                className="nav-items"
                sx={{
                  pt: 0,
                  transition: "padding .25s ease",
                  "& > :first-of-type": { mt: "0" },
                  pr: !navCollapsed || (navCollapsed && navHover) ? 4.5 : 1.25,
                  pl: !navCollapsed || (navCollapsed && navHover) ? 4.5 : 1.25,
                }}
              >
                <VerticalNavItems
                  groupActive={groupActive}
                  setGroupActive={setGroupActive}
                  currentActiveGroup={currentActiveGroup}
                  setCurrentActiveGroup={setCurrentActiveGroup}
                  {...props}
                />
              </List>
            )}
            {afterVerticalNavMenuContent &&
            afterVerticalNavMenuContentPosition === "static"
              ? afterVerticalNavMenuContent(props)
              : null}
          </ScrollWrapper>
        </Box>
      </Box>
      {afterVerticalNavMenuContent &&
      afterVerticalNavMenuContentPosition === "fixed"
        ? afterVerticalNavMenuContent(props)
        : null}
      <Box sx={{ display: "flex", alignItems: "center", gap: 2, pl: 9, pb: 5 }}>
        {mobileNumber && <img src={phoneIcon} />}
        <Typography
          variant="fm-p2"
          color={"#544D65"}
          fontWeight={500}
          sx={{
            "&:hover": {
              color: "#602DE1",
              fontWeight: 700,
            },
          }}
        >
          {mobileNumber}
        </Typography>
      </Box>
    </Drawer>
  );
};

export default Navigation;
