// ** Next Imports
import {
  Link,
  NavLink,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";

// ** MUI Imports
import Chip from "@mui/material/Chip";
import ListItem from "@mui/material/ListItem";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import ListItemIcon from "@mui/material/ListItemIcon";
import { styled, useTheme } from "@mui/material/styles";
import ListItemButton from "@mui/material/ListItemButton";

// ** Configs Import
import themeConfig from "src/configs/themeConfig";

// ** Custom Components Imports
import UserIcon from "src/layouts/components/UserIcon";
import Translations from "src/layouts/components/Translations";
import CanViewNavLink from "src/layouts/components/acl/CanViewNavLink";

// ** Utils
import { handleURLQueries } from "src/@core/layouts/utils";
import {
  Button,
  Drawer,
  Menu,
  MenuItem,
  IconButton,
  Divider,
} from "@mui/material";
import { useEffect, useState } from "react";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import ExpandLessOutlinedIcon from "@mui/icons-material/ExpandLessOutlined";
import VerticleITSolutionDropDown from "src/views/pages/ITSolutionDetails/VerticleITSolutionDropDown";
import ServiceDropDown from "src/views/pages/Services/ServiceDropDown";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { ApiEndpoints } from "src/network/endpoints";
import { axiosInstance } from "src/network/adapter";
import { useAuth } from "src/hooks/useAuth";

// ** Styled Components
const MenuNavLink = styled(ListItemButton)(({ theme }) => ({
  width: "100%",
  borderRadius: 100,
  borderTopRightRadius: 100,
  borderBottomRightRadius: 100,
  color: theme.palette.text.primary,
  transition: "padding-left .25s ease-in-out",
  "&.active": {
    "&, &:hover": {
      boxShadow: theme.shadows[3],
      backgroundColor: theme.palette.primary.main,
    },
    "& .MuiTypography-root, & .MuiListItemIcon-root": {
      color: `${theme.palette.common.white} !important`,
    },
  },
}));

const MenuItemTextMetaWrapper = styled(Box)({
  // width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  transition: "opacity .25s ease-in-out",
  ...(themeConfig.menuTextTruncate && { overflow: "hidden" }),
});

const VerticalNavLink = ({
  item,
  parent,
  navHover,
  settings,
  navVisible,
  isSubToSub,
  collapsedNavWidth,
  toggleNavVisibility,
  navigationBorderWidth,
}) => {
  // ** Hooks
  const theme = useTheme();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { resources, overview, features, ITSolutionId } = useAuth();

  // ** Vars
  const { skin, navCollapsed } = settings;
  const IconTag = parent && !item.icon ? themeConfig.navSubItemIcon : item.icon;

  //Getting ID from localstorage for IT Solution Pages
  // const itSolutionId = localStorage.getItem("ITSolutionId");

  const conditionalBgColor = () => {
    if (skin === "semi-dark" && theme.palette.mode === "light") {
      return {
        color: `rgba(${theme.palette.customColors.dark}, 0.87)`,
        "&:hover": {
          backgroundColor: `rgba(${theme.palette.customColors.dark}, 0.04)`,
        },
      };
    } else if (skin === "semi-dark" && theme.palette.mode === "dark") {
      return {
        color: `rgba(${theme.palette.customColors.light}, 0.87)`,
        "&:hover": {
          backgroundColor: `rgba(${theme.palette.customColors.light}, 0.04)`,
        },
      };
    } else return {};
  };

  const isNavLinkActive = () => {
    if (
      location.pathname === item.path ||
      handleURLQueries({ ...location, query: searchParams }, item.path)
    ) {
      return true;
    } else {
      return false;
    }
  };
  //IT Solution Drawer
  const [openDrawer, setOpenDrawer] = useState(false);

  const toggleDrawer = (newOpen) => () => {
    setOpenDrawer(newOpen);
  };

  //Resources Dropdown
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    event.preventDefault();
    event.stopPropagation();
  };

  const handleClose = (event) => {
    setAnchorEl(null);
  };

  //services dropdown
  const [openServices, setOpenServices] = useState(false);

  const toggleServicesDrawer = (newOpen) => () => {
    setOpenServices(newOpen);
  };

  const [backArrowVisible, setBackArrowVisible] = useState(false);

  // Array of paths to check against
  const validPaths = [
    "/it-solution-overview",
    "/it-solution-features",
    "/it-solution-demo",
    "/it-solution-resources",
    "/it-solution-getQuote",
    "/it-solution-supportUs",
  ];

  useEffect(() => {
    // Check if the current pathname matches any of the valid paths
    const isMatch = validPaths.some((path) =>
      location.pathname.startsWith(path)
    );

    setBackArrowVisible(isMatch); // Update state based on the match
  }, [location.pathname]);

  // const fetchCommonData = () => {
  //   axiosInstance
  //     .get(ApiEndpoints.COMMON.list(itSolutionId))
  //     .then((response) => {
  //       setFeatures(response.data.features.is_show);
  //       setOverview(response.data.overView.is_show);
  //       setResources(response.data.resources.is_show);
  //     });
  // };

  // // useEffect(() => {
  // //   fetchCommonData();
  // // }, []);

  if (
    (item.title === "Resources" && !resources) ||
    (item.title === "Overview" && !overview) ||
    (item.title === "Features" && !features)
  ) {
    return null; // Don't render the ListItem if `resources` is true
  }
  return (
    // <CanViewNavLink CanViewNavLink navLink={item} >
    <ListItem
      disablePadding
      className="nav-link"
      disabled={item.disabled || false}
      sx={{ mt: 1.5, px: "0 !important" }}
    >
      {/* {console.log("itemmmm", item)} */}
      <MenuNavLink
        // to={item.path === undefined ? "/" : `${item.path}`}
        // to={
        //   item.path === undefined
        //     ? "/"
        //     : [
        //         "Overview",
        //         "Features",
        //         "Demo",
        //         "Resources",
        //         "Get Quote",
        //         "Support",
        //       ].includes(item.title)
        //     ? `${item.path}/${itSolutionId}`
        //     : `${item.path}`
        // }
        to={
          [
            "Overview",
            "Features",
            "Demo",
            "Resources",
            "Get Quote",
            "Support",
          ].includes(item.title)
            ? `${item.path}/${ITSolutionId}`
            : `${item.path}`
        }
        component={NavLink}
        className={isNavLinkActive() ? "active" : ""}
        {...(item.openInNewTab ? { target: "_blank" } : null)}
        onClick={(e) => {
          if (item.path === undefined) {
            e.preventDefault();
            e.stopPropagation();
          }
          if (navVisible) {
            toggleNavVisibility();
          }
        }}
        sx={{
          py: 2.25,
          ...conditionalBgColor(),
          ...(item.disabled
            ? { pointerEvents: "none" }
            : { cursor: "pointer" }),
          pl:
            navCollapsed && !navHover
              ? (collapsedNavWidth - navigationBorderWidth - 24) / 8
              : 5.5,
          pr:
            navCollapsed && !navHover
              ? ((collapsedNavWidth - navigationBorderWidth - 24) / 2 - 5) / 4
              : 3.5,
        }}
      >
        {/* {isSubToSub ? null : (
          <ListItemIcon
            sx={{
              color: 'text.primary',
              transition: 'margin .25s ease-in-out',
              ...(navCollapsed && !navHover ? { mr: 0 } : { mr: 2.5 }),
              ...(parent ? { ml: 1.25, mr: 3.75 } : {}) // This line should be after (navCollapsed && !navHover) condition for proper styling
            }}
          >
            <UserIcon
              icon={IconTag}
              componentType='vertical-menu'
              iconProps={{
                sx: {
                  fontSize: '0.875rem',
                  ...(!parent ? { fontSize: '1.5rem' } : {}),
                  ...(parent && item.icon ? { fontSize: '0.875rem' } : {})
                }
              }}
            />
          </ListItemIcon>
        )} */}

        <MenuItemTextMetaWrapper
          sx={{
            ...(isSubToSub ? { ml: 9 } : {}),
            ...(navCollapsed && !navHover ? { opacity: 0 } : { opacity: 1 }),
          }}
        >
          {/* <Typography
            {...((themeConfig.menuTextTruncate ||
              (!themeConfig.menuTextTruncate && navCollapsed && !navHover)) && {
              noWrap: true,
            })}
          >
            <Translations text={item.title} />
          </Typography> */}

          <Box>
            {item.title === "IT Solutions" ? (
              <Box>
                {backArrowVisible ? (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 3,
                      cursor: "pointer",
                    }}
                    onClick={toggleDrawer(true)}
                  >
                    <Box
                      sx={{
                        bgcolor: "#E9E8EE",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "30px",
                        width: "30px",
                        borderRadius: "50px",
                        pl: 1,
                      }}
                    >
                      <ArrowBackIosIcon
                        sx={{
                          fontSize: "15px",
                          color: "#602DE1",
                        }}
                      />
                    </Box>
                    {/* <ArrowBackIcon
                      sx={{
                        fontSize: "18px",
                        color: "#00000",
                      }}
                    /> */}
                    <Typography
                      sx={{
                        p: 0,
                        pb: 0,
                        fontSize: "16px",
                        color: "#544D65",
                        fontWeight: "500",
                        "&:hover": {
                          backgroundColor: "transparent",
                        },
                      }}
                    >
                      {" "}
                      IT Solutions
                    </Typography>
                  </Box>
                ) : (
                  <Box onClick={toggleDrawer(true)}>
                    <Typography
                      sx={{
                        p: 0,
                        pb: 0,
                        fontSize: "16px",
                        color: "#544D65",
                        fontWeight: "500",
                        "&:hover": {
                          backgroundColor: "transparent",
                        },
                      }}
                    >
                      {" "}
                      IT Solutions
                    </Typography>
                  </Box>
                )}

                <Drawer
                  open={openDrawer}
                  onClose={toggleDrawer(false)}
                  PaperProps={{
                    sx: { width: "100vw", maxWidth: "1024px" }, // Set max width or use a percentage
                  }}
                >
                  <VerticleITSolutionDropDown
                    openNavBar={toggleNavVisibility}
                    onClose={toggleDrawer(false)}
                    openDrawer={toggleDrawer(true)}
                  />
                </Drawer>
              </Box>
            ) : item.title === "Resources" && !item.path ? (
              <Box sx={{ display: "flex", gap: 1 }} onClick={handleClick}>
                {" "}
                <Typography
                  id="resources-button"
                  aria-controls={open ? "resources-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  sx={{
                    p: 0,
                    pt: 0,
                    fontSize: "16px",
                    color: "#544D65",
                    fontWeight: "500",
                    "&:hover": {
                      backgroundColor: "transparent",
                    },
                  }}
                >
                  {" "}
                  Resources
                </Typography>
                <Box>
                  {open ? (
                    <ExpandLessOutlinedIcon
                      sx={{ fontSize: "25px", color: "#544D65" }}
                    />
                  ) : (
                    <ExpandMoreOutlinedIcon
                      sx={{ fontSize: "25px", color: "#544D65" }}
                    />
                  )}
                </Box>
              </Box>
            ) : (
              <Typography
                {...((themeConfig.menuTextTruncate ||
                  (!themeConfig.menuTextTruncate &&
                    navCollapsed &&
                    !navHover)) && {
                  noWrap: true,
                })}
              >
                <Translations text={item.title} />
              </Typography>
            )}

            {/* Resources Menu */}
            {item.title === "Resources" && (
              <Menu
                id="resources-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "resources-button",
                }}
                sx={{
                  "& .MuiPaper-root": {
                    borderRadius: "16px",
                    px: 2,
                  },
                }}
                PaperProps={{
                  sx: {
                    width: "170px",
                  },
                }}
              >
                <Link to="/clients" style={{ textDecoration: "none" }}>
                  <MenuItem
                    onClick={(event) => {
                      event.stopPropagation(); // Prevent drawer from closing
                      handleClose(); // Close the menu
                    }}
                  >
                    Client
                  </MenuItem>
                </Link>
                <Divider sx={{ m: 0 }} />
                <Link to="/blogs" style={{ textDecoration: "none" }}>
                  <MenuItem
                    onClick={(event) => {
                      event.stopPropagation(); // Prevent drawer from closing
                      handleClose(); // Close the menu
                    }}
                  >
                    Blogs
                  </MenuItem>
                </Link>
                <Divider sx={{ m: 0 }} />
                <Link to="/caseStudy" style={{ textDecoration: "none" }}>
                  <MenuItem
                    onClick={(event) => {
                      event.stopPropagation(); // Prevent drawer from closing
                      handleClose(); // Close the menu
                    }}
                  >
                    Case Study
                  </MenuItem>
                </Link>
              </Menu>
            )}
          </Box>
          {item.title === "Services" && (
            <Box>
              {openServices ? (
                <IconButton
                  id="basic-button"
                  aria-controls={openServices ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={openServices ? "true" : undefined}
                  onClick={toggleServicesDrawer(true)}
                  sx={{ p: 0, ml: 1, mb: 0.5 }}
                >
                  <ExpandLessOutlinedIcon
                    sx={{
                      fontSize: "25px",
                      color: isNavLinkActive() ? "#ffff" : "#544D65",
                    }}
                  />
                </IconButton>
              ) : (
                <IconButton
                  id="basic-button"
                  aria-controls={openServices ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={openServices ? "true" : undefined}
                  onClick={toggleServicesDrawer(true)}
                  sx={{ p: 0, ml: 1, mb: 0.5 }}
                >
                  <ExpandMoreOutlinedIcon
                    sx={{
                      fontSize: "25px",
                      color: isNavLinkActive() ? "#ffff" : "#544D65",
                    }}
                  />
                </IconButton>
              )}

              {/* Drawer for Services */}
              <Drawer open={openServices} onClose={toggleServicesDrawer(false)}>
                <ServiceDropDown
                  openNavBar={toggleNavVisibility}
                  onClose={toggleServicesDrawer(false)}
                />
              </Drawer>
            </Box>
          )}
          {item.badgeContent ? (
            <Chip
              label={item.badgeContent}
              color={item.badgeColor || "primary"}
              sx={{
                ml: 1.25,
                height: 20,
                fontWeight: 500,
                "& .MuiChip-label": { px: 1.5, textTransform: "capitalize" },
              }}
            />
          ) : null}
        </MenuItemTextMetaWrapper>
      </MenuNavLink>
    </ListItem>
    // </CanViewNavLink >
  );
};

export default VerticalNavLink;
