import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Typography,
} from "@mui/material";
import React from "react";
import ArrowForwardOutlinedIcon from "@mui/icons-material/ArrowForwardOutlined";
import provideSolutionImg from "../../../assets/images/provideSolutions.png";
import implementationImg from "../../../assets/images/implementation.png";
import trainingImg from "../../../assets/images/training.png";
import { useNavigate } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

const ServiceDropDown = ({ onClose, openNavBar }) => {
  const services = [
    {
      title: "Provide Solutions",
      description:
        "Tailored Manage Engine solutions to meet your unique business needs, enhancing efficiency and productivity with cutting-edge technology. Our experts analyze your requirements and design solutions that align perfectly with your goals. From initial consultation to deployment, we ensure a seamless experience. Trust us to provide innovative solutions that drive success.",
      img: provideSolutionImg,
    },
    {
      title: "Implementation & Configuration",
      description:
        "Seamless implementation and meticulous configuration of Manage Engine products, ensuring optimal performance and integration with your existing systems. Our team handles every detail, from installation to customization, to fit your specific environment. We follow best practices to minimize disruption and maximize effectiveness. Count on us for a smooth transition and robust configuration.",
      img: implementationImg,
    },
    {
      title: "Training & Certification",
      description:
        "Comprehensive training programs and certifications to empower your team with the skills and knowledge needed to maximize Manage Engine capabilities. Our training covers all aspects, from basic operations to advanced features, ensuring your team is proficient and confident. Certification validates their expertise, enhancing their value and your organization’s performance. Invest in our training to achieve mastery and excellence.",
      img: trainingImg,
    },
  ];
  const navigate = useNavigate();
  const handleNavigate = (value) => {
    navigate("/services", { state: { serviceType: value } });
    if (onClose) {
      onClose();
    }
  };
  const handleSeeAllClick = () => {
    navigate("/services");
    if (onClose) {
      onClose();
    }
  };
  const handleClick = () => {
    if (onClose || openNavBar) {
      openNavBar();
      onClose();
    }
  };
  return (
    <>
      <Box sx={{ width: { lg: "1280px" }, px: { lg: 15 } }}>
        <Box sx={{ display: { lg: "none", xs: "block" } }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 4,
              p: 3,
              cursor: "pointer",
            }}
            onClick={handleClick}
          >
            <Box
              sx={{
                bgcolor: "#E9E8EE",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "35px",
                width: "35px",
                borderRadius: "50px",
                pl: 1,
              }}
            >
              <ArrowBackIosIcon
                sx={{
                  fontSize: "20px",
                  color: "#602DE1",
                }}
              />
            </Box>
            <Typography
              sx={{
                fontSize: { xs: "20px", sm: "26px" },
                lineHeight: { xs: "24px", sm: "31px" },
                color: "#000000",
                fontWeight: 500,
              }}
            >
              Back
            </Typography>
          </Box>
        </Box>

        <Grid container>
          <Grid
            item
            xs={12}
            lg={2.5}
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 5,
              px: { xs: 5, lg: 0 },
              py: 3,
            }}
          >
            <Typography
              sx={{
                fontSize: { lg: "24px" },
                lineHeight: { lg: "32px" },
                fontWeight: 600,
                color: "#602DE1",
              }}
            >
              Services
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 5,
                pl: { lg: 3 },
              }}
            >
              <Typography
                sx={{
                  fontSize: { lg: "16px" },
                  lineHeight: { lg: "24px" },
                  fontWeight: 600,
                  color: "#101828",
                  "&:hover": {
                    color: "#602DE1",
                  },
                }}
                onClick={() => handleNavigate("engine")}
              >
                Manage Engine Services
              </Typography>
              <Typography
                sx={{
                  fontSize: { lg: "16px" },
                  lineHeight: { lg: "24px" },
                  fontWeight: 600,
                  color: "#101828",
                  "&:hover": {
                    color: "#602DE1",
                  },
                }}
                onClick={() => handleNavigate("zoho")}
              >
                Zoho Services
              </Typography>
            </Box>
            <Box>
              <Button
                onClick={handleSeeAllClick}
                type="submit"
                variant="outlined"
                sx={{
                  fontSize: { xs: "14px", md: "16px" },
                  fontWeight: 600,
                  borderRadius: "141px",
                  padding: "3px 24px",
                }}
                endIcon={<ArrowForwardOutlinedIcon />}
              >
                SEE ALL
              </Button>
            </Box>
          </Grid>
          {services.map((item, index) => (
            <Grid item xs={12} lg={3.1} key={index}>
              <Card sx={{ boxShadow: "none" }}>
                <CardContent
                  sx={{ display: "flex", flexDirection: "column", gap: 5 }}
                >
                  <Box className="image-zoom">
                    <img src={item.img} />
                  </Box>
                  <Box>
                    <Typography
                      sx={{
                        fontSize: { lg: "16px" },
                        lineHeight: { lg: "24px" },
                        color: "#101828",
                        fontWeight: 500,
                      }}
                      gutterBottom
                    >
                      {item.title}
                    </Typography>
                    <Typography
                      sx={{
                        textWrap: "wrap",
                        WebkitLineClamp: 4,
                        fontSize: { lg: "14px" },
                        lineHeight: { lg: "20px" },
                        color: "#667085",
                        fontWeight: 400,
                        transition: "color 0.3s ease-in-out", // Smooth color transition
                        "&:hover": {
                          color: "#602DE1", // Hover color
                        },
                      }}
                    >
                      {item.description}
                    </Typography>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
    </>
  );
};

export default ServiceDropDown;
